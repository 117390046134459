import React from "react";
import {
  Alert,
  AspectRatio,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Chip,
  Container,
  Divider,
  FormControl,
  FormLabel,
  List,
  ListItem,
  Option,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Step,
  StepIndicator,
  Stepper,
  Table,
  Typography,
} from "@mui/joy";
import logo from "./FullLogo_plnofarebné_Data Set 58-01.svg";
import { Check } from "@mui/icons-material";

const pricingTable: Record<string, Record<string, Record<string, number>>> = {
  "2023": {
    classic: {
      basic: 50,
      secondChild: 35,
      third: 1,
      discounted: 1,
      volunteer: 30,
    },
    late: {
      basic: 55,
      secondChild: 40,
      third: 1,
      discounted: 1,
      volunteer: 35,
    },
  },
  "2024": {
    classic: {
      basic: 60,
      secondChild: 45,
      third: 1,
      discounted: 1,
      volunteer: 35,
      volunteerRecommended: 50,
    },
    late: {
      basic: 70,
      secondChild: 55,
      third: 1,
      discounted: 1,
      volunteer: 40,
      volunteerRecommended: 50,
    },
  },
};

const transl = {
  volunteer: "Dobrovoľník",
  discounted: "Hmotná núdza/ZŤP/Ústavná starostlivosť",
  basic: "Základný",
};

const transl_s = {
  volunteer: "Dobrovoľník",
  discounted: "HN",
  basic: "Základný",
};

function App() {
  const [error, setError] = React.useState("");
  const [step, setStep] = React.useState(1);
  const steps = {
    1: "Výber roku",
    2: "Údaje o registrovaných",
  };

  const stepsLength = Object.entries(steps).length;

  const [selYear, setSelYear] = React.useState<string | null>(null);
  const availableYears = Object.keys(pricingTable);
  const [regType, setRegType] = React.useState("");
  const [people, setPeople] = React.useState<
    { t: "basic" | "discounted" | "volunteer"; a: "18+" | "<18" }[]
  >([]);
  const [pAddType, setPAddType] = React.useState<string | null>("");

  const reset = () => {
    setError("");
    setSelYear(null);
    setRegType("");
    setPeople([]);
    setPAddType(null);
    setStep(1);
  };

  const calculatePrices = () => {
    if (!selYear) throw new Error();
    let children = 0;
    let total = 0;
    const final = [];
    // HACK TODO: temporary fix for basic pricing being counted first
    people.sort((a, b) => b.t.length - a.t.length);
    people.sort((a, b) => {
      if (a.a === "<18" && b.a === "18+") return 1;
      else if (a.a === "18+" && b.a === "<18") return -1;
      else return 0;
    });
    for (const p of people) {
      if (p.t === "discounted") {
        final.push({
          t: p.t,
          a: p.a,
          p: (
            pricingTable as Record<
              string,
              Record<string, Record<string, number>>
            >
          )[selYear][regType][p.t],
        });
      } else {
        let type: string = p.t;
        const initialPrice = pricingTable[selYear][regType][type];
        if (
          children === 1 &&
          p.a === "<18" &&
          initialPrice > pricingTable[selYear][regType]["secondChild"]
        )
          type = "secondChild";
        if (
          total >= 2 &&
          p.a === "<18" &&
          initialPrice > pricingTable[selYear][regType]["third"]
        )
          type = "third";
        final.push({
          t: p.t,
          a: p.a,
          p: pricingTable[selYear][regType][type],
        });
      }

      if (p.a === "<18") children++;
      total++;
    }
    return final;
  };

  const readyForNext = () => {
    switch (step) {
      case 1: {
        return selYear && regType;
      }
      case 2: {
        return people.length > 0;
      }
    }
  };

  const addPerson = (minor: boolean) => {
    if (!pAddType) {
      setError("Vyberte typ člena");
    } else {
      setError("");
      setPeople([
        ...people,
        {
          t: pAddType as "basic" | "discounted" | "volunteer",
          a: minor ? "<18" : "18+",
        },
      ]);
    }
  };

  const nextStep = () => {
    if (readyForNext()) {
      setError("");
      setStep(step + 1);
    } else {
      setError("Vyplňte všetky polia");
    }
  };

  const YearSelection = () => (
    <Box>
      <FormControl required>
        <FormLabel id="select-year-button-label" htmlFor="select-year-button">
          Kalendárny rok registrácie
        </FormLabel>
        <Select
          slotProps={{
            button: {
              id: "select-year-button",
              "aria-labelledby": "select-year-button-label",
            },
          }}
          defaultValue={""}
          value={selYear}
          onChange={(e, val) => setSelYear(val)}
          placeholder="Vyberte rok"
        >
          {availableYears.map((y) => (
            <Option value={y}>{y}</Option>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ mt: ".5rem" }} />
      <FormControl required>
        <FormLabel>Termín platby</FormLabel>
        <RadioGroup
          defaultValue="late"
          value={regType}
          onChange={(e) => setRegType(e.target.value)}
        >
          <Radio value="classic" label="Zaplatím do 14.1" />
          <Radio value="late" label="Zaplatím po 14.1" />
          {/* <Radio value="late-sept" label="Zaplatím po 1.9" /> */}
        </RadioGroup>
      </FormControl>
    </Box>
  );

  const RegisteredPeople = () => (
    <Box sx={{ mt: "1rem" }}>
      <Stack
        direction={"column"}
        divider={<Divider sx={{ my: "1rem" }} orientation="horizontal" />}
        justifyContent={"space-between"}
      >
        <Box>
          <Typography>Pridať člena</Typography>
          <FormControl required>
            <FormLabel>Typ člena</FormLabel>
            <Select
              placeholder="Typ člena"
              value={pAddType}
              onChange={(e, val) => setPAddType(val)}
            >
              {(
                [
                  { v: "basic", d: "" },
                  {
                    v: "discounted",
                    d: "Vyberte po dohode so zborovým vodcom",
                  },
                  {
                    v: "volunteer",
                    d: "Vyberte, pokiaľ daný člen dobrovoľníči v zbore. ",
                  },
                ] as { v: keyof typeof transl; d: string }[]
              ).map((type) => (
                <Option value={type.v} label={transl[type.v]}>
                  <Box component="span" sx={{ display: "block" }}>
                    <Typography color="danger" level="title-sm">
                      {transl[type.v]}
                    </Typography>
                    <Typography level="body-xs">{type.d}</Typography>
                  </Box>
                </Option>
              ))}
            </Select>
          </FormControl>
          <ButtonGroup sx={{ mt: "1rem" }}>
            <Button variant="outlined" onClick={() => addPerson(false)}>
              Pridať plnoletého
            </Button>
            <Button
              color="primary"
              variant="solid"
              onClick={() => addPerson(true)}
            >
              Pridať ako neplnoletého
            </Button>
          </ButtonGroup>
        </Box>
        <Box>
          <Typography>Zoznam registrovaných</Typography>
          <List>
            {people.map((p, i) => (
              <ListItem>
                {i + 1}: <Chip variant="solid">{transl_s[p.t]}</Chip>{" "}
                <Chip variant="solid">{p.a}</Chip>
              </ListItem>
            ))}
          </List>
        </Box>
      </Stack>
    </Box>
  );

  const Summary = () => (
    <Box>
      <Typography level="h3" gutterBottom>
        Sumár - poplatky
      </Typography>
      <Table color="primary" variant="solid">
        <thead>
          <tr>
            <th>Člen</th>
            <th>Poplatok</th>
          </tr>
        </thead>
        <tbody>
          {calculatePrices().map((p, i) => (
            <tr key={`table-prices-r${i}`}>
              <td>
                {transl[p.t]} ({p.a})
              </td>
              <td>min. {p.p}€</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th>Spolu: </th>
            <th>
              min.{" "}
              {calculatePrices()
                .map((p) => p.p)
                .reduce((p, c) => p + c)}
              €
            </th>
          </tr>
        </tfoot>
      </Table>
      <Typography sx={{ mt: "1rem" }} level="body-lg">
        Prosíme, pred platbou nezabudnite vyplniť registračný formulár.{" "}
        <b>Bez neho vašu platbu nedokážeme spracovať.</b>
        <br />
        Tieto poplatky boli vygenerované na rok <u>{selYear}</u>. Pred platením
        si skontrolujte, či ste vyplnili kalkulačku správne.
        <br />V prípade potreby je možné dohodnúť sa tiež na splátkovom
        kalendári alebo inom riešení. Určite nás neváhajte kontaktovať.
        <br />
        Ak by ste chceli viac podporiť našu činnosť, je možné zaplatiť vyšší
        členský poplatok. Vopred Vám za túto formu podpory ďakujeme.
      </Typography>
    </Box>
  );

  return (
    <Container sx={{ display: "flex", justifyContent: "center" }}>
      <Card variant="soft" sx={{ width: "100%" }}>
        <AspectRatio ratio={3} variant="plain" objectFit="contain">
          <img src={logo} alt="MO logo" />
        </AspectRatio>
        <Typography level="title-lg">
          Kalkulačka registračných poplatkov
        </Typography>
        <Divider inset="none" />
        <CardContent>
          <Stepper>
            {[...Object.entries(steps)].map(([k, v]) => (
              <Step
                key={`${k}-step`}
                indicator={
                  Number(k) < step ? (
                    <StepIndicator variant="outlined" color="success">
                      <Check />
                    </StepIndicator>
                  ) : (
                    <StepIndicator variant="outlined">{k}</StepIndicator>
                  )
                }
              >
                {v}
              </Step>
            ))}
          </Stepper>
          <Divider inset="none" sx={{ mt: "0.5rem" }} />
          {error && (
            <Alert variant="solid" color="danger">
              {error}
            </Alert>
          )}
          {step === 1 && <YearSelection />}
          {step === 2 && <RegisteredPeople />}
          {step === 3 && <Summary />}
        </CardContent>
        <CardActions>
          <Button color="danger" onClick={() => reset()}>
            Reset
          </Button>
          <Box sx={{ flexGrow: 1 }} />
          {step > 1 && (
            <Button variant="outlined" onClick={() => setStep(step - 1)}>
              Späť
            </Button>
          )}
          {step <= stepsLength && (
            <Button variant="solid" onClick={() => nextStep()}>
              {step === stepsLength ? "Dokončiť" : "Ďalej"}
            </Button>
          )}
        </CardActions>
      </Card>
    </Container>
  );
}

export default App;

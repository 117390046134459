import { Box } from "@mui/joy";

export default function Background() {
  return (
    <Box
      sx={{
        zIndex: "-1",
        position: "fixed",
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        backgroundColor: "#00B5E2",
        opacity: 1,
        backgroundImage:
          "linear-gradient(135deg, #00B5E2 25%, transparent 25%), linear-gradient(225deg, #00B5E2 25%, transparent 25%), linear-gradient(45deg, #00B5E2 25%, transparent 25%), linear-gradient(315deg, #00B5E2 25%, #14d0ff 25%)",
        backgroundPosition: "40px 0, 40px 0, 0 0, 0 0",
        backgroundSize: "80px 80px",
        backgroundRepeat: "repeat",
      }}
    />
  );
}

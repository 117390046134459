import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { CssVarsProvider, CssBaseline, Grid } from "@mui/joy";
import Background from "./background";
import '@fontsource/georama';
import { theme } from "./theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <CssVarsProvider theme={theme} defaultMode="light">
      <CssBaseline />
      <Background />
      <Grid container columns={12} gap={2}>
        <Grid xs={0} sm={3} md={3} />
        <Grid xs={12} sm={6} md={6}>
          <App />
        </Grid>
        <Grid xs={0} sm={3} md={3} />
      </Grid>
    </CssVarsProvider>
  </React.StrictMode>
);

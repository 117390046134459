import { extendTheme } from "@mui/joy";

export const theme = extendTheme({
  fontFamily: {
    body: "Georama",
    fallback: "sans-serif",
  },
  colorSchemes: {
    dark: {
      palette: {
        text: {
          primary: "var(--joy-palette-primary-500)",
        },
        primary: {
          "500": "#002855",
          outlinedColor: "var(--joy-palette-common-white)",
        },
        neutral: {
          "500": "#fce9bf",
        },
        danger: {
          "500": "#e03c31",
        },
        success: {
          "500": "#00965e",
        },
        warning: {
          "500": "#ed8b00",
        },
        background: {},
        divider: "#e03c31",
      },
    },
  },
});
